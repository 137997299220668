import React from 'react';
//import Card from "./Card"
import DealCard from "../../features/DealCard"
import Cookies from 'universal-cookie';
import WhatsappSignup from '../../features/WhatsappBanner/WhatsappSignup';
import configData from '../../config/config.json';

//import Flights from "./Flights"
const API_ENDPOINT = configData.API_ENDPOINT;

class Deck extends React.PureComponent {
    constructor(props) {
        super(props)
        this.counter = 0;

        // read login cookie
        const cookies = new Cookies();
        if (cookies.get('afnu_member')!=null) {
            this.member = "1"
        }
        else
            this.member = "0";

        // Load airlines baggage only once
        /*if (this.state?.baggage==null) {

            const res = fetch(API_ENDPOINT + `/search/airlines/baggage`) // /${this.props.data.flight.outbound.airline.code}/${this.props.data.flight.outbound.arrival_iata}
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 309)');
                    }
                }).then(data=>{
                    this.setState({
                        //...prevState,
                        baggage: data,
                    })
                }).catch(error=>{
                    console.log(error)
                    throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 309)');
                });
            
        }*/
    }

  

    render() {

        if ( this.props.ready && this.props.data?.length===0 ){
            window.dataLayer = window.dataLayer || [];
            const event_name = 'NoSearchResults';
            console.log('GTM event: ' + event_name)
            window.dataLayer.push({
                page: 'Homepage',
                event: event_name,
                'pageUrl': window.location,
                'pageTitle' : window.title,
            });

            return (
                <div className="noresults">
                    <h2 style={{fontWeight:700}}>לא נמצאו תוצאות מתאימות</h2>
                    <h3 style={{fontWeight:600,marginTop:'1em'}}>אנא שנו את העדפות החיפוש ונסו שנית</h3>
                </div>
            );
        }
    
        let cardsData = this.props?.data?.map((item, index) => {
            this.counter++;
            // Inject whatsapp banners evey N destinations
            if ((this.counter==2 || this.counter==7 || ( this.counter > 7 && this.counter%7==0)) && (item?.deals && item.deals.length > 0))
                return (<>
                       <DealCard data={item} destination={item?.destination} member={this.member} key={'card'+index} id={this.counter} type={this.props.type} nights={this.props.nights} meals={this.props.meals} min_stars={this.props.min_stars} stars={this.props.stars} from_date={this.props.from_date} to_date={this.props.to_date} dates_type={this.props.dates_type} months={this.props.months} expired={this.props?.expired} deals={this.props?.deals} promotion={this.props?.promotion} adults={this.props?.adults} teens={this.props?.teens} children={this.props?.children} infants={this.props?.infants} dow={this.props?.dow} waterpark={this.props?.waterpark} luxury={this.props?.luxury} casino={this.props?.casino} near_chabad={this.props?.near_chabad}  spa={this.props?.spa} airlines={this.props?.airlines}  />
                       <WhatsappSignup></WhatsappSignup>
                       </>)
            else
                return <DealCard data={item} destination={item?.destination} member={this.member} key={'card'+index} id={this.counter} type={this.props.type} nights={this.props.nights} meals={this.props.meals} min_stars={this.props.min_stars} stars={this.props.stars} from_date={this.props.from_date} to_date={this.props.to_date} dates_type={this.props.dates_type} months={this.props.months}  expired={this.props?.expired} deals={this.props?.deals} promotion={this.props?.promotion} adults={this.props?.adults} teens={this.props?.teens} children={this.props?.children} infants={this.props?.infants} dow={this.props?.dow}  waterpark={this.props?.waterpark} luxury={this.props?.luxury} casino={this.props?.casino} near_chabad={this.props?.near_chabad} spa={this.props?.spa} airlines={this.props?.airlines} />

        }); 

/*        if (window.location.pathname.indexOf('flights')>0) 
           cardsData = this.props.data.map(item => <Flights data={item} key={this.counter++} />); 
*/

        //let cardsData = deckData.filter(item => item.flights!=null)
        //const deckData = this.props.data;
        //const dd  = this.prepareOutput(deckData);
     
        /*cardsData = cardsData
            .filter(function(item) {
                let flights = item.flights;
                flights = flights.filter(flight => flight.flyFrom = 'ETMr');
                item.flights = flights;
                return item;
                }).map(item => <Card data={item}/>)*/
        //cardsData = cardsData.filter(item => item.flights.filter(flight => flight.price > 300))
        //card_flights = cardsData.filter(item=> item.flights);
        //card_flights = card_flights.filter (flight => flight.price > 300);
        //cardsData = cardsData.map(item => <Card data={item}/>)

        return (
            <div className="deck" key='deck_key1'>
                {cardsData}
            </div>
        )
    }
}
/*
function Deck() {

    const cardsData = deckData.map(item => <Card data={item}/>)

    return (
        <div className="deck">
            {cardsData}
        </div>

    )
}*/

export default Deck  