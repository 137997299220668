import React from 'react';
import configData from '../../config/config.json';
import Header from "../../features/Layout/Header"
import Footer from "../../features/Layout/Footer"
import Deck from "./Deck"
//import Search from "../../features/FiltersManager/Search"
import FiltersManager from "../../features/FiltersManager"
import Heading from "../../features/Heading"
import './index.css'
import { validateYupSchema } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const API_ENDPOINT = configData.API_ENDPOINT;
const API_QUERY = "?";//"get/?lang=he";
let API_PREDEFINED_QUERY = "";
let API_OBJECT = "/search/packages/";

class Destination extends React.Component {

  constructor(props) {
    
    super(props);

    // Load query string parameters or set default values
    let stars = new URLSearchParams(props.location.search).get("stars")?new URLSearchParams(props.location.search).get("stars").split(','):[];
    let meals = new URLSearchParams(props.location.search).get("meals")?new URLSearchParams(props.location.search).get("meals").split(','):[];
    let holidays = new URLSearchParams(props.location.search).get("holidays")?new URLSearchParams(props.location.search).get("holidays").split(','):[];
    let dates_type = new URLSearchParams(props.location.search).get("dates_type")?new URLSearchParams(props.location.search).get("dates_type"):'';
    let nights = new URLSearchParams(props.location.search).get("nights")?new URLSearchParams(props.location.search).get("nights").split(','):[];
    let from_date = new URLSearchParams(props.location.search).get("from_date")?new URLSearchParams(props.location.search).get("from_date"):'';
    let to_date = new URLSearchParams(props.location.search).get("to_date")?new URLSearchParams(props.location.search).get("to_date"):'';
    let months = new URLSearchParams(props.location.search).get("months")?(new URLSearchParams(props.location.search).get("months")).split(','):[];
    let month = new URLSearchParams(props.location.search).get("month")?(new URLSearchParams(props.location.search).get("month")):'';
    //let sortby = new URLSearchParams(props.location.search).get("sortby")?new URLSearchParams(props.location.search).get("sortby"):'price-asc';
    let destinations = (new URLSearchParams(props.location.search).get("destinations")&&new URLSearchParams(props.location.search).get("destinations")!=='')===true?new URLSearchParams(props.location.search).get("destinations").split(','):[];
    if (destinations.length===0) 
      destinations = (new URLSearchParams(props.location.search).get("destination")&&new URLSearchParams(props.location.search).get("destination")!=='')===true?new URLSearchParams(props.location.search).get("destination").split(','):[];
    let adults = new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults"):2;
    let teens = new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens"):0;
    let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children"):0;
    let infants = new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants"):0;
    let dow = new URLSearchParams(props.location.search).get("dow")?new URLSearchParams(props.location.search).get("dow"):'';
    let waterpark = new URLSearchParams(props.location.search).get("waterpark") || new URLSearchParams(props.location.search).get("waterparks")?true:false;
    let luxury = new URLSearchParams(props.location.search).get("luxury") || new URLSearchParams(props.location.search).get("luxury")?true:false;
    let casino = new URLSearchParams(props.location.search).get("casino") || new URLSearchParams(props.location.search).get("casino")?true:false;
    let near_chabad = new URLSearchParams(props.location.search).get("chabad") || new URLSearchParams(props.location.search).get("chabad")?true:false;
    let spa = new URLSearchParams(props.location.search).get("spa") || new URLSearchParams(props.location.search).get("spa")?true:false;
    let airlines = new URLSearchParams(props.location.search).get("airlines")?(new URLSearchParams(props.location.search).get("airlines")).split(','):[];
    let page = new URLSearchParams(props.location.search).get("page")?(new URLSearchParams(props.location.search).get("page")):'';

    this.state = {
      'monthsContent': [],
      //'daysContent': [],
      'destinations': [],
      'carousel_items': [],
      'isLoading'   : true,
      'error'       : null,
      'show_destinations_popup': false,
      'filters' : {
        'window': {
          'visible': false,
          'active_tab': 'search',
          'refresh_count': 0,
          'step': '0'
        },
        'origin': {
          'iata': {
            'TLV': true,
            'ETM': false
          },
        },
        //'from_airport'  : 'TLV',
        'stars'         : stars,
        'meals'         : meals,
        'holidays'      : holidays,
        'dates_type'    : dates_type,
        'nights'        : nights,
        'from_date'     : from_date,
        'to_date'       : to_date,
        'limit'         : 10,
        'destination_name': this.city,
        'destinations'  : destinations,
        //'destination'  : this.props?.destination,
        //'sortby'        : sortby,
        'months'        : months,
        'month'         : month,
        'page'          : page,
        'adults'        : adults,
        'teens'        : teens,
        'children'      : children,
        'infants'       : infants,
        'dow'           : dow,
        'airlines'      : airlines,
        'highlights'    : {
          'waterpark'     : waterpark,
          'luxury'        : luxury,
          'casino'        : casino,
          'near_chabad'        : near_chabad,
          'spa'        : spa,
          'loading_deals' : false
        }
      },
      cities: [],
      heading_key: 0
    }
    
    // binding
    this.getFilters = this.getFilters.bind(this); 
    this.fetchDestinations = this.fetchDestinations.bind(this);
    //this.handleClose = this.handleClose.bind(this); 
    this.openSearch = this.openSearch.bind(this);
    this.showDestinationSelectionPopup = this.showDestinationSelectionPopup.bind(this);
    this.handleCloseSearchWindow = this.handleCloseSearchWindow.bind(this);
    this.closeSearchWindow = this.closeSearchWindow.bind(this);
    this.getDestinationDetails = this.getDestinationDetails.bind(this);
    this.showSearchWindow = this.showSearchWindow.bind(this);
    this.gotoStep = this.gotoStep.bind(this); 

    const base = this.props.history.location.pathname.split('/');
    this.city = base[1];
    this.month_name = base[2];
    this.month = month.split(',')[0].split('-')[0];
    this.year  = month.split(',')[0].split('-')[0];

    // load destination
  }

  componentDidMount() {
    //console.log('componentDidMount')
    this.refetchData()

    this.backListener = this.props.history.listen((action => {
      this.LoadQueryStringAndCreateState(action.search, true)
    }));

  }

  setDescription = desc => {
    const el = document.querySelector("meta[name='description']");
    el.setAttribute('content',desc)
  }

  UNSAFE_componentWillMount() {

    let title = "דילים ל" ;
    if (this.city) 
      title+= this.city;
    if (this.month_name && this.month_name!=='')
      title+= ' ב' + this.month_name;

    title += ' | עפנו חופשות סודיות';
    
    document.title = title;
    this.setDescription(`✈ לא נמאס לכם לזרוק כסף? רק בעפנו מזמינים דיל זול ל${this.city}${this.month_name?` בחודש ${this.month_name}`:``} הכולל טיסה ומלון בבמחיר זול באמת!`)
  }
  
  
  async LoadQueryStringAndCreateState(url, update=false) {
    
    const props = this.props;
    
    // Load query string parameters or set default values
    let stars = new URLSearchParams(props.location.search).get("stars")?new URLSearchParams(props.location.search).get("stars").split(','):[];
    let meals = new URLSearchParams(props.location.search).get("meals")?new URLSearchParams(props.location.search).get("meals").split(','):[];
    let holidays = new URLSearchParams(props.location.search).get("holidays")?new URLSearchParams(props.location.search).get("holidays").split(','):[];
    let dates_type = new URLSearchParams(props.location.search).get("dates_type")?new URLSearchParams(props.location.search).get("dates_type"):'';
    let nights = new URLSearchParams(props.location.search).get("nights")?new URLSearchParams(props.location.search).get("nights").split(','):[];
    let from_date = new URLSearchParams(props.location.search).get("from_date")?new URLSearchParams(props.location.search).get("from_date"):'';
    let to_date = new URLSearchParams(props.location.search).get("to_date")?new URLSearchParams(props.location.search).get("to_date"):'';
    let months = new URLSearchParams(props.location.search).get("months")?(new URLSearchParams(props.location.search).get("months")).split(','):[];
    let month = new URLSearchParams(props.location.search).get("month")?(new URLSearchParams(props.location.search).get("month")).split(','):'';
    //let sortby = new URLSearchParams(props.location.search).get("sortby")?new URLSearchParams(props.location.search).get("sortby"):'price-asc';
    let destinations = (new URLSearchParams(props.location.search).get("destinations")&&new URLSearchParams(props.location.search).get("destinations")!=='')===true?new URLSearchParams(props.location.search).get("destinations").split(',')[0]:[];
      if (destinations.length===0)
        destinations = (new URLSearchParams(props.location.search).get("destination")&&new URLSearchParams(props.location.search).get("destination")!=='')===true?new URLSearchParams(props.location.search).get("destination").split(',')[0]:[];
    let adults = new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults"):2;
    let teens = new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens"):0;
    let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children"):0;
    let infants = new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants"):0;
    let dow = new URLSearchParams(props.location.search).get("dow")?new URLSearchParams(props.location.search).get("dow"):'';
    let waterpark = new URLSearchParams(props.location.search).get("waterpark") || new URLSearchParams(props.location.search).get("waterparks")?true:false;
    let luxury = new URLSearchParams(props.location.search).get("luxury") || new URLSearchParams(props.location.search).get("luxury")?true:false;
    let casino = new URLSearchParams(props.location.search).get("casino") || new URLSearchParams(props.location.search).get("casino")?true:false;
    let near_chabad = new URLSearchParams(props.location.search).get("chabad") || new URLSearchParams(props.location.search).get("chabad")?true:false;
    let spa = new URLSearchParams(props.location.search).get("spa") || new URLSearchParams(props.location.search).get("spa")?true:false;
    let airlines = new URLSearchParams(props.location.search).get("airlines")?(new URLSearchParams(props.location.search).get("airlines")).split(','):[];
    let page = new URLSearchParams(props.location.search).get("page")?(new URLSearchParams(props.location.search).get("page")).split(','):'';


    const current_location = props.location.search;


    this.state = {
      //'daysContent': [],
      'monthsContent': [],
      'destinations': [],
      'carousel_items': [],
      'isLoading'   : true,
      'error'       : null,
      'show_destinations_popup': false,
      'filters' : {
        'window': {
          'visible': false,
          'active_tab': 'search',
          'refresh_count': 0
        },
        'origin': {
          'iata': {
            'TLV': true,
            'ETM': false
          },
        },
        //'from_airport'  : 'TLV',
        'stars'         : stars,
        'meals'         : meals,
        'holidays'      : holidays,
        'dates_type'    : dates_type,
        'nights'        : nights,

        'from_date'     : from_date,
        'to_date'       : to_date,
        'limit'         : 10,
        'destination_name': this.city,
        'destinations'  : destinations,
        //'sortby'        : sortby,
        'months'        : months,
        'month'         : month,
        'page'          : page,
        'adults'        : adults,
        'teens'        : teens,
        'children'      : children,
        'infants'       : infants,
        'dow'           : dow,
        'waterpark'     : waterpark,
        'luxury'        : luxury,
        'casino'        : casino,
        'near_chabad'   : near_chabad,
        'spa'           : spa,
        'airlines'           : airlines,
        'loading_deals' : false
      },
      cities: [],
      heading_key: 0
    }
  
  }

  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  refetchData(updateURL=false) {
      //console.log('Refetch2')

      this.query_options = new Map();
      this.query_options.set('stars',this.state.filters.stars);
      this.query_options.set('meals',this.state.filters.meals);
      this.query_options.set('holidays',this.state.filters.holidays);
      this.query_options.set('dates_type',this.state.filters.dates_type);
      this.query_options.set('nights',this.state.filters.nights);
      //this.query_options.set('from_airport',this.state.filters.from_airport);
      this.query_options.set('months',this.state.filters.months);
      this.query_options.set('month',this.state.filters.month);
      //this.query_options.set('sortby',this.state.filters.sortby);
      this.query_options.set('from_date',this.state.filters.from_date);
      this.query_options.set('to_date',this.state.filters.to_date);
      this.query_options.set('adults',this.state.filters.adults);
      this.query_options.set('teens',this.state.filters.teens);
      this.query_options.set('children',this.state.filters.children);
      this.query_options.set('infants',this.state.filters.infants);
      this.query_options.set('dow',this.state.filters?.dow);
      this.query_options.set('waterpark',this.state.filters?.highlights?.waterpark);
      this.query_options.set('luxury',this.state.filters?.highlights?.luxury);
      this.query_options.set('casino',this.state.filters?.highlights?.casino);
      this.query_options.set('chabad',this.state.filters?.highlights?.near_chabad);
      this.query_options.set('spa',this.state.filters?.highlights?.spa);
      this.query_options.set('airlines',this.state.filters.airlines);
      this.query_options.set('destinations',this.state.filters?.destinations);
      this.query_options.set('page',this.state.filters.page);
      this.query_options.set('limit', 1); // for some reason when I set this it shows 3 results per month :)
  
      let querystring_array = [];

      // Remove empty keys
      // Sort values (if array)
      this.query_options.forEach((value,key) => {
        if (value && value!='') {
            if (Array.isArray(value))
              value = value.sort();

            querystring_array.push(`${key}=${value}`)  
        }
      });

      // Sort result array by keys & rebuild querystring
      let querystring=querystring_array.sort((a,b)=>a-b).join('&');
      const url =  API_ENDPOINT + API_OBJECT + API_QUERY + querystring + API_PREDEFINED_QUERY
      
      if (updateURL) {
        // Remove PAGE parameter from querystring
        //querystring = querystring.split(',').filter(item=>item.indexOf('page')!==false);1
        if (!(this.state?.filters?.page > 1))
          this.props.history.push(window.location.pathname + API_QUERY + querystring + API_PREDEFINED_QUERY)
      }

      return (
        fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
          }
        })
        .then(data => {

          if (!data || !data?.destinations || !(data?.destinations.length>0)) {

            console.log('No deal')
            console.log(this.state)

            if (this.state?.filters?.page>1) {
            
              this.setState(prevState=>({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  loading_deals: false,
                  no_more_deals: true
                }
              }))
            } else  {
              this.setState(prevState=>({
                ...prevState,
                'isLoading': false,
                destinations: [{
                  deals: []
                }] 
              }))
            }

            return
          }

          let deals = data.destinations[0].deals;
          if (this.state?.filters?.page>1) {
            deals = this.state?.destinations[0].deals.concat(data.destinations[0].deals);
          }
          
          this.setState(prevState=>({
              ...prevState,
              //'destinations': this.state?.destinations,
              'monthsContent': data.months,
              //'daysContent': data?.days,
              'destinations': [{
                deals: deals
              }],
              'isLoading': false,
              filters: {
                ...prevState.filters,
                loading_deals: false
              }
            }),()=>{

            }
         )
        })
        .catch(error => {
          
          console.log('ERROR')
          console.log(error)
          this.setState(prevState=>({
            ...prevState,
            error, 
            'isLoading': false,
            destinations:[{
              deals: []
            }]
        }))
      }
        )
      )
  }
 
  getDestinationDetails(data) {
    //console.log('getDestinationDetails')
    //console.log(data)
    this.setState(prevState=>({
        ...prevState,
        //'destination_sid': destination.sid,
        //'destination': destination
        filters: {
          ...prevState.filters,
          destination: data,
          //r: Math.random()
        }
      })
    )
  }

 
  /**
   * 
   * @param {*} step 
   * @param {*} next_step_close 
   */
  showSearchWindow(step='0', next_step_close=false) {
    console.log('Show search wizard ')
    console.log(next_step_close)
    console.log(step+'')

    this.setState((prevState) => (
      {
          ...prevState,
          filters: {
              ...prevState.filters,
              'window': {
                  ...prevState.filters.windows,
                  'visible': true,
                  'active_tab': 'search',
                  'step': step,
                  'next_step_close': next_step_close
              }
          }            
      }
  ));
}

  async fetchDestinations() {

    console.log(`Fetch destinations`)
    const API_ENDPOINT = configData.API_ENDPOINT;
    const url =  API_ENDPOINT + '/search/destinations/?lang=he';

    const results = await fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
          }
        })

    this.setState(prevState=>(
      {
        ...prevState,
        'all_destinations': results
      }
    ))

}


  getFilters(filtersVal, updateURL=false) {

      //  If search window submit butto was clicked
      if (updateURL) { 
        
          filtersVal.QS = null;
          //console.log(filtersVal)
          const queryString = Object.keys(filtersVal).map(key => filtersVal[key]&&filtersVal[key]!=''&&key!='window'&&key!='origin'&&key!='rooms'?key + '=' + filtersVal[key]:null).filter(item=>item!=null).join('&');
          //console.log(queryString)

          // Incase we're in a specific 1 destination + month page dont change the url
          if (filtersVal?.destinations.length!==1) {

            if (this.props.match.path==="/:destination_name/:month" && (!filtersVal?.month || (filtersVal?.month && filtersVal?.month===''))) {
                window.location = `/${this.city}/?${queryString}`;
                return;
            }
          }

          if  (!filtersVal?.destinations || (filtersVal?.destinations && (filtersVal?.destinations.length==0 || filtersVal?.destinations==='' || filtersVal?.destinations.length > 1))) {
            window.location = '/?' + queryString        
          }
      }

      console.log('REFETCH...')
      this.setState(prevState=>({
          ...prevState,
          'isLoading': filtersVal.page>1?false:true, 
          'filters': {
            ...prevState.filters,
            ...filtersVal
          }
      }),() => this.refetchData(true));
      
  }

  openSearch(event) {

    this.setState(prevState=> ({
      ...prevState,
      //'destinations': this.state.destinations,
      //'isLoading': false, 
      filters: {
        ...prevState.filters,
        'window': {
          ...prevState.filters.window,
          'visible': true,
          //'refresh_count': this.state.filters.window.refresh_count?this.state.filters.window.refresh_count++:0,
        },
       }
   }));
  }

  
  handleCloseSearchWindow() {

    this.setState((prevState)=>({
        ...prevState,
        filters: {
          ...prevState.filters,
          window: {
            ...prevState.filters.window,
            visible: false
          }
        }
      })
    )
  }

  /*

  handleClose() {
    //alert('closing')

    this.setState({
      //'destinations': this.state?.destinations, 
      'monthsContent': this.state.monthsContent,
      //'daysContent': this.state.daysContent,
      destinations: this.state.destinations,
      'isLoading': false, 
      filters: {...this.state.filters }
    })
  }*/
  
  handleFiltersClick = (event) => {
    window.dataLayer = window.dataLayer || [];
    const event_name = 'FiltersClick';
    console.log('GTM event: ' + event_name)
    window.dataLayer.push({
        page: 'destination',
        event: event_name,
        'pageUrl': window.location,
        'pageTitle' : window.title,
    });
  }

  handleToggleDestiantionTab = (event) => {
    const currentState = this.state;
    currentState.filters.window.visible = true;
    currentState.filters.window.active_tab = 'destination';
    this.setState(currentState);

    window.dataLayer = window.dataLayer || [];
    const event_name = 'DestinationChangeClicked';
    console.log('GTM event: ' + event_name)
    window.dataLayer.push({
        event: event_name,
        'pageUrl': window.location,
        'pageTitle' : window.title,
    });
    // refresh data
  }

  showDestinationSelectionPopup() {

    this.setState(prevState=>({
      ...prevState,
      'show_destinations_popup': true,
      'heading_key': ++prevState.heading_key
    }));
  }

  closeSearchWindow() {
    this.setState((prevState) => (
      {
          ...prevState,
          filters: {
              ...prevState.filters,  
              'window': {
                  ...prevState.filters.windows,
                  'visible': false,
              }
          }            
      }
  ));
  }

  gotoStep = (e, step, next_step_close=false) => {
    console.log('gotoStep', step)
    e.stopPropagation()

    // If we've click the step 1 button but it's empty jumpt to step 2
    if (step=='2') {
        if (this.state.filters?.dates_type==='exact'|| (this.state.filters?.holidays?.length>0 && !this.state.filters.holidays.find(holiday=>holiday.includes('school-summer-vacation'))) )
            step='3';
    }

    this.setState((prevState) => (
        {
           ...prevState,
            filters: {
                ...prevState.filters,  
                'window': {
                    ...prevState.filters.window,
                    'step': step+'',
                    'visible': true,//prevState.filters?.window?.visible?prevState.filters?.window?.visible:false,
                    'next_step_close': next_step_close
                    //'active_tab': 'search'
                }
            }            
        }
    ),()=>{
      console.log(this.state.filters)
    })
}

  
  render() {

    // conditional rendreing ahead
    /*const {error} = this.state;

    if (error) {
      return <p>{error.message}</p>;
    }*/

    //console.log(this.props.match.path) 
    // this.props.match.path == '/:destination_name'
    // this.props.match.path == '/:destination_name/:month'

    //console.log(this.state?.days)

    const month_names_he = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
                 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];

    /*const city_id = this.state.filters.destination;
    const city_uid = this.state?.destinations[0]?.destination?.city_uid;
    const city_name = this.state?.destinations[0]?.destination?.city_name;
    const country_name = this.state?.destinations[0]?.destination?.country_name;*/
    const city_id = this.state.filters?.destination?.city_id;
    //const city_uid = this.state.filters?.destination?.sid;
    const city_name = this.state.filters?.destination?.city_name
    const country_name = this.state.filters?.destination?.country_name;


    return (
      <div className="App overlay">
        <Header 
          hide='true' 
          navigation='hide' 
          sendDataToCaller={this.showSearchWindow} 
          location={this.props?.location} 
          filters={this.state?.filters} 
          page_type='destination'
          openSearchWindow={this.showSearchWindow} 
          
        />
                        
            {/*<div className="headerImagePlaceHolder"></div>      
            <h1 style={{fontWeight:'400', fontSize: '1.8em', padding: '0.5em', background: 'linear-gradient(90deg,#01bddd 3%,#124b71  45%)', color: '#fff'}}>חופשות ב{this.props.match.params.destination_name}</h1>*/}


            {/*<h1 style={{fontWeight:'800',  padding:0, color: '#0795b7', margin: '0.75em auto .5em'}}>חופשות ב{!this.state?.destinations[0]?.destination?.city_name?this.props.match.params.destination_name:this.state?.destinations[0]?.destination?.city_name}</h1>*/}
            <div className='destination'>
                <div className={"image " + this.state.filters?.destination?.sid}>
                <div className="heading_wrapper">
                  <Heading key={'header'+this.state?.heading_key} show_popup={this.state?.show_destinations_popup} active='destination' destinations={this.state?.cities} destination={this.state?.filters?.destination} month={this.state?.filters?.month} destination_id={this.state?.filters?.destinations} destination_name={city_name} filters={this.state.filters} sendDataToCaller={this.getDestinationDetails} text='דילים' />        
                </div>

                </div>
            </div>
            <div className="content">
              
              <FiltersManager 
                filters={this.state.filters} 
                openSearch={this.state.filters?.window?.visible?this.state.filters?.window?.visible:false} 
                destinations={this.state?.all_destinations} 
                sendDataToCaller={this.getFilters} 
                showSearchWindow={this.showSearchWindow} 
                closeSearchWindow={this.closeSearchWindow}
                gotoStep={this.gotoStep}
                location={this.props.location} 
                month_name={this.month_name} 
                month={this.month} 
                months={this?.months}
                fetchDestinations={this.fetchDestinations}
                year={this.year}></FiltersManager>
              
              <div className={'preloader ' + (this.state.isLoading?' show-item':' hide-item')}>
                <FontAwesomeIcon icon={faSpinner} spin size="3x" style={{margin:'.5em auto'}} />
              </div>

              <div  ref={this.myDivToFocus} style={{maxWidth:'1336px', padding:'0', margin:'0 auto'}} className={'deck-stars ' + (this.state.isLoading?' hidden':' ')}>
                {
                  this.state.destinations && this.state.destinations[0] && this.state.destinations[0].deals && this.state.destinations[0]?.deals.length > 0 ? 
                    <Deck filters={this.state.filters} sendDataToCaller={this.getFilters} data={this.props.match.path === '/:destination_name'?this.state.monthsContent:this.state.destinations} ready={!this.state.isLoading} type={this.props.match.path === '/:destination_name'?'months':'days'} key="deck1" destination={this.state?.filters?.destination} meals={this.state?.filters?.meals} nights={this.state?.filters?.nights} stars={this.state?.filters?.stars} months={this.state?.filters?.months}  month={(this.month_name && this.month_name!=='')?this.month_name:undefined} page={this.state?.filters?.page} adults={this.state?.filters?.adults} teens={this.state?.filters?.teens} children={this.state?.filters?.children} infants={this.state?.filters?.infants}  dow={this.state?.filters?.dow} city={city_name} country={country_name} waterpark={this.state?.filters?.highlights?.waterpark} luxury={this.state?.filters?.highlights?.luxury} casino={this.state?.filters?.highlights?.casino} near_chabad={this.state?.filters?.highlights?.near_chabad} spa={this.state?.filters?.highlights?.spa} airlines={this.state?.filters?.airlines} />:
                    <div style={{margin:'2em 1em 3em'}}>
                      <h3>
                      לא הצלחנו למצוא תוצאות מתאימות</h3>
                      <br />
                      <h5>מומלץ לשנות את הגדרות החיפוש ולנסות שנית</h5>
                    </div>
                }
              </div>
            </div>
          
          <Footer hide={(this.state.isLoading?true:false)}/>
        </div>
      );
    }
  }

export default Destination;
