import React, {Component} from 'react';
//import MonthCard from "./_MonthCard";
import DealCard from "../../features/DealCard";
import YearCard from "./YearCard"
import Dragscrolles from 'dragscrolles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faAngleUp, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import configData from '../../config/config.json';

const API_ENDPOINT = configData.API_ENDPOINT;


//import Flights from "./Flights"


class Deck extends Component {
    
    constructor(props) {
        super(props)
        //console.log(props)
            


        this.counter  = 0;
        this.yPosition = 0;

        this.state = {
            'showmore': 3,
            'active': true,
            //'data' : this.props.data,
            'sort' : 'price',
            'filters': this.props.filters
        }
        
         // Load airlines baggage only once
         /*
         if (this.state?.baggage==null) {

            const res = fetch(API_ENDPOINT + `/search/airlines/baggage`) // /${this.props.data.flight.outbound.airline.code}/${this.props.data.flight.outbound.arrival_iata}
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 309)');
                    }
                }).then(data=>{
                    this.setState((prevState)=>({
                        ...prevState,
                        baggage: data,
                    }))
                }).catch(error=>{
                    console.log(error)
                    throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 309)');
                });
            
        }*/

        //binding
        this.handleSort = this.handleSort.bind(this); 
        this.toggleMore = this.toggleMore.bind(this); 
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.handleSort = this.handleSort.bind(this); 


    }

    componentDidMount() {

        const scrolls = document.querySelectorAll('.scroll');

        scrolls.forEach((scroll) => {
            new Dragscrolles(scroll);
        });
    }

    next() {
        this.slider.slickNext();
    }
      
    previous() {
        this.slider.slickPrev();
    }

    /** 
     * Sort array by either price or day/month
    */
    sortArray = (array, sortBy) => {
        
        const daysOrMonths = JSON.parse(JSON.stringify(array)); // Copy the array by value (not by reference)
        //console.log(daysOrMonths)
        // Remove all non related stars deals
        /*array.map(
            (day, index) => {
                const deal = day.deals.filter(deal=>
                    deal.property.stars == stars);
                daysOrMonths[index].deals = deal;
            }
        );*/

        return sortBy=='counter'?
            daysOrMonths.sort((a,b) => ((a.year+('0'+a.id).slice(-2))-(b.year+('0'+b.id).slice(-2)))): // we're using slice(-2) to add zero padding => reference to the slice(-2): https://stackoverflow.com/questions/3605214/javascript-add-leading-zeroes-to-date
            daysOrMonths.sort((a,b) => a.deals[0]?.price-b.deals[0]?.price)
    }

    // Handle sort button
    handleSort = (sortBy) => {
        //console.log('SORT')
        this.setState(
            {
                data: 
                    sortBy=='counter'?
                        this.sortArray(this.props.data, 'counter'):
                        this.sortArray(this.props.data, 'price'),
                sort: sortBy
            }
        );
    }


    toggleMore = (evt) => {

        evt.preventDefault();       
        evt.stopPropagation()

        window.dataLayer = window.dataLayer || [];
        const event_name = 'ShowMoreCategoryResults';
        console.log('GTM event: ' + event_name)
        window.dataLayer.push({
            page: 'destination/month',
            event: event_name,
            //name: stars,
            //stars: stars,
            'pageUrl': window.location,
            'pageTitle' : window.title,
        });
        
        this.setState(prevState => ({
            ...prevState,
            active: true,
            sort: prevState.sort,
            filters: {
                ...this.props.filters,
                'page': (this.props?.filters?.page)?++this.props.filters.page:2,
                'loading_deals': true,
                'no_more_deals': false
            }
            /*'text':     (!prevState.showfor3stars.showMore ? "הסתר" : " תאריכים נוספים" ),
            'card_deals': prevState.showMore?this.data_source.slice(0,13):this.data_source,
            'fa_arrow': (!prevState.showMore ? "fa fa-sort-up" : "fa fa-sort-down" ),
            'showMore' : !prevState.showMore*/
        }), () => {
            this.props.sendDataToCaller(this.state.filters, false)
        });
        
    }

    /**
         * Track the existing scrollbar position
         * 
         * @param {*} e 
         */
    scrollButtonToggle = (scrollContainer, cardIndex, cardsCount) => {

        const btnLeft = scrollContainer.parentElement.getElementsByClassName('btn left')[0];
        const btnRight = scrollContainer.parentElement.getElementsByClassName('btn right')[0];
   
        if (cardIndex > 0)
            btnRight.style.display = 'block'
        else
            btnRight.style.display = 'none'

        if (cardIndex < cardsCount)
            btnLeft.style.display = 'block'
        else
            btnLeft.style.display = 'none'

    }

    /**
     * Get up-to-date y axis position when scrolling by dragging
     * @param e 
     */
    scrollHandler = (e) => {
        e.stopPropagation();
        this.yPosition = e.target.scrollLeft           
        //console.log(yPosition)
    }

    scrollButtonClickHandler = (e, direction)  => {

        e.stopPropagation();
        
        var scrollContainer =  e.target.parentElement.parentElement.parentElement.getElementsByClassName("scroll")[0]
        var scrollContainerItems = scrollContainer.getElementsByClassName("scrollItem");
        
        // claculate the gap between 2 cards
        var gapSize = scrollContainerItems[1].offsetLeft - scrollContainerItems[0].offsetLeft

        // Find the closest index to the existing y-Position
        let scrollItemIndex = Math.abs(parseInt(this.yPosition/gapSize));

        // Calculate the new index
        if (direction == 'right') {
            if (scrollItemIndex > 0)
                scrollItemIndex=scrollItemIndex-1;
        } else {
            if (scrollItemIndex < scrollContainerItems.length)
                scrollItemIndex=scrollItemIndex+1
        }

        // Scroll to new card
        scrollContainer.scrollTo({
            top: 0,
            //left: scrollContainerItems[newCardIndex].offsetLeft,
            left: scrollItemIndex * gapSize,
            behavior: "smooth"
        });

        // Toggle next & previous buttons
        this.scrollButtonToggle(scrollContainer, scrollItemIndex, scrollContainerItems.length-1)


    }

    getStars = (stars) => {

        let starsCaption = '';

        switch(stars) {
            case '3': starsCaption='★★★';break;
            case '4': starsCaption='★★★★';break;
            case '5': starsCaption='★★★★★';break;
        }

        return starsCaption;
    }    

    

    render() {
        this.counter = 0;

        if ( this.props.ready && this.props.data?.length===0 ) {
            window.dataLayer = window.dataLayer || [];
            const event_name = 'NoSearchResultsInDestination';
            console.log('GTM event: ' + event_name)
            window.dataLayer.push({
                page: 'Destination',
                event: event_name,
                'pageUrl': window.location,
                'pageTitle' : window.title,
            });

            return (
                <div>
                <h2>לא נמצאו תוצאות מתאימות</h2>
                <h3>אנא שנו את , עדפות החיפוש ונסו שנית</h3>
                </div>
            );
        }

        

        let source_data = this.props?.data;
        let cardsData = source_data?.map((item, idx) => 
            <DealCard 
                id={this.counter} 
                data={item}
                //baggage={this.state?.baggage} 
                member={this.member} 
                key={'monthcard' + idx} 
                type={this.props.type}  
                counter={this.counter} 
                destination={this.props.destination} 
                show={this.state.showmore}  
                stars={this.props?.stars} 
                nights={this.props.nights}  
                month={this.props.month} 
                months={this.props?.months} 
                page={this.props?.page} 
                adults={this.props.adults} 
                teens={this.props.teens} 
                children={this.props.children} 
                infants={this.props.infants}  
                dow={this.props.dow} 
                city={this.props?.city} 
                country={this.props?.country} 
                active={this.state?.active_card}
                waterpark={this.props.waterpark}
                luxury={this.props.luxury}
                near_chabad={this.props.near_chabad}
                spa={this.props.spa}
                casino={this.props.casino}
                airlines={this.props?.airlines}
                />
        )

        if (this.props.type=='months') {    

            cardsData = source_data.sort((a,b)=>new Date(a.year,a.id,1).getTime()-new Date(b.year,b.id,1).getTime()).map(item => 
                (
                    item.deals.length>0?
                        <YearCard data={item} member={this.member} key={'monthcard'+(this.counter++)} id={'monthcard'+(this.counter)} type={this.props.type} min_price={item} destination={this.props.destination} stars={this.props?.stars} meals={this.props.meals} nights={this.props.nights}  adults={this.props.adults} teens={this.props.teens}  children={this.props.children} infants={this.props.infants} dow={this.props.dow} waterpark={this.props?.waterpark} luxury={this.props?.luxury} casino={this.props?.casino} near_chabad={this.props?.near_chabad} spa={this.props?.spa} airlines={this.props?.airlines} />:<></>
                )
            )
        }


        //this.state.data = cardsData

/*        if (window.location.pathname.indexOf('flights')>0) 
           cardsData = this.props.data.map(item => <Flights data={item} key={this.counter++} />); 
*/

        //let cardsData = deckData.filter(item => item.flights!=null)
        //const deckData = this.props.data;
        //const dd  = this.prepareOutput(deckData);
     
        /*cardsData = cardsData
            .filter(function(item) {
                let flights = item.flights;
                flights = flights.filter(flight => flight.flyFrom = 'ETMr');
                item.flights = flights;
                return item;
                }).map(item => <Card data={item}/>)*/
        //cardsData = cardsData.filter(item => item.flights.filter(flight => flight.price > 300))
        //card_flights = cardsData.filter(item=> item.flights);
        //card_flights = card_flights.filter (flight => flight.price > 300);
        //cardsData = cardsData.map(item => <Card data={item}/>)

        
        //let scrollItemIndex = 0;

    
    
        //return this.props.type == 'months'?(
        if (this.props.type != 'months')
            /**
             * IF WE ARE AT A DESTINATION+MONTH PAGE 
             */
            return (
                <div>
                    {/* 
                    <div style={{display:'none',  whiteSpace:'nowrap', margin:'1em 1em 0 .5em'}} >
                        <select name="sortMonths" value={this.state.sort} 
                            style={{alignSelf:'center', 
                            height:'min-content', 
                            fontSize:'0.9em',
                            fontWeight:400,
                            backgroundColor:'#fff', 
                            color:'#0f1b22', 
                            margin:'0px 0 0px 0.5em', 
                            padding:'0.4em 1.5em 0.4em 1em', 
                            backgroundImage:`url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20384%20512%22%3E%3C!--!%20Font%20Awesome%20Pro%206.1.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20(Commercial%20License)%20Copyright%202022%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%22M192%20384c-8.188%200-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75%200-45.25s32.75-12.5%2045.25%200L192%20306.8l137.4-137.4c12.5-12.5%2032.75-12.5%2045.25%200s12.5%2032.75%200%2045.25l-160%20160C208.4%20380.9%20200.2%20384%20192%20384z%22%2F%3E%3C%2Fsvg%3E')`}} onChange={e=>this.handleSort(e.target.value)}>
                            <option value="price">סדר לפי מחיר</option>
                            <option value="counter">{this.props.type=='months'?'סדר לפי חודשים':'סדר לפי תאריכים'}</option>
                        </select>
                    </div>
                    */}
                    <div className="deck noselect" key='deck_key1'>
                        <div className="container" style={{padding:0}}>
                            <div className={'card month scrollItem ' + (this.props.member==="0" ?'locked':'unlocked')} id={'scroll-3-card-'+this.props.id} 
                                style={{  margin:'0 auto'}}>
                                
                                <div  style={{display:'flex',flexFlow:'wrap',justifyContent:'center'}} >
                                {cardsData}
                                </div>   
                                    {
                                        this.props?.filters?.loading_deals? (
                                            <><FontAwesomeIcon icon={faSpinner} spin size="3x" style={{margin:'1em auto 2em'}} /></>
                                        )
                                        : !this?.props?.filters?.no_more_deals?(
                                            <div className={"more-results "} onClick={e=>this.toggleMore(e)}>
                                                <div>
                                                    <button>
                                                        הצג דילים נוספים</button>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faAngleDoubleDown}  />
                                                        </div>
                                                </div>
                                            </div>):
                                        (<></>)
                                        
                                    }
                                    

                            </div>

                            

                        </div>
                        


                    </div>
                </div>
            )
        else
            return (
                <div>
                    <div style={{display:'flex',justifyContent:'space-between', whiteSpace:'nowrap', display:'none'}} >
                        <select name="sortMonths" value={this.state.sort} style={{alignSelf:'center', height:'min-content', fontSize:'0.9em',fontWeight:400,backgroundColor:'#fff', color:'#0f1b22', margin:'0px 0 0px 0.5em', padding:'0.4em 1.5em 0.4em 1em', backgroundImage:`url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20384%20512%22%3E%3C!--!%20Font%20Awesome%20Pro%206.1.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20(Commercial%20License)%20Copyright%202022%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%22M192%20384c-8.188%200-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75%200-45.25s32.75-12.5%2045.25%200L192%20306.8l137.4-137.4c12.5-12.5%2032.75-12.5%2045.25%200s12.5%2032.75%200%2045.25l-160%20160C208.4%20380.9%20200.2%20384%20192%20384z%22%2F%3E%3C%2Fsvg%3E')`}} onChange={e=>this.handleSort(e.target.value)}>
                            <option value="price">סדר לפי מחיר</option>
                            <option value="counter">{this.props.type=='months'?'סדר לפי חודשים':'סדר לפי תאריכים'}</option>
                        </select>
                    </div>
                    <div className="deck noselect" key='deck_key1'>
                        <div className="container" style={{padding:0}}>{cardsData}</div>
                    </div>
                </div>
            )
                
          
    }
}
/*
function Deck() {

    const cardsData = deckData.map(item => <Card data={item}/>)

    return (
        <div className="deck">
            {cardsData}
        </div>

    )
}*/

export default Deck  