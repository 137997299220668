import React from "react";
import GlobalFilters from "./GlobalFilters"
import Search from "./Search"
import {Grid, Snackbar} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';

class FiltersManager extends React.Component {

    constructor (props) {
        super(props)
        console.log(`FiltersManager constructor`)

        this.state = {
            'filters' : {
                ...this.props?.filters,
                rooms: [
                    {
                        // convert the numeric N value of adults to an array with N elements - for example if N=2 the result will be [30,30]
                        adults: this.props?.filters?.adults && this.props?.filters?.adults > 0 ? Array(this.props?.filters?.adults*1).fill(30) : [],
                        teens: this.props?.filters?.teens && this.props?.filters?.teens > 0 ? Array(this.props?.filters?.teens*1).fill(16) : [],
                        children: this.props?.filters?.children && this.props?.filters?.children > 0 ? Array(this.props?.filters?.children*1).fill(11) : [],
                        infants: this.props?.filters?.infants && this.props?.filters?.infants > 0 ? Array(this.props?.filters?.infants*1).fill(1) : [],
                    }
                ],
            },
            drawer: {
                open: false
            },
            err:''
        }

        // init
        this.max_guests_per_room = 6;
        this.max_adults_per_room = 6;
        this.max_children_per_room = 5;
        this.max_guests_per_booking = 6;


        console.log(this.state)
        // Binding
        this.toggleStars = this.toggleStars.bind(this); 
        this.handleSelectDestination = this.handleSelectDestination.bind(this); 
        
        this.handleSelectOrigin = this.handleSelectOrigin.bind(this); 
        
        this.handleChangeCheckbox  = this.handleChangeCheckbox.bind(this);
        this.handleSelectMonth  = this.handleSelectMonth.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this); 
        this.handleSubmit = this.handleSubmit.bind(this); 
        
        this.toggleDates  = this.toggleDates.bind(this);
        this.toggleDOW  = this.toggleDOW.bind(this);
        //this.toggleButton = this.toggleButton.bind(this); 
        this.toggleMonth = this.toggleMonth.bind(this); 
        this.toggleStars = this.toggleStars.bind(this); 
        this.toggleChildren = this.toggleChildren.bind(this);
        this.toggleMealCode = this.toggleMealCode.bind(this); 
        this.toggleWaterpark = this.toggleWaterpark.bind(this); 
        this.toggleCasino = this.toggleCasino.bind(this); 
        this.toggleNearChabad = this.toggleNearChabad.bind(this); 
        this.toggleResetSpecialHotel = this.toggleResetSpecialHotel.bind(this);
        this.toggleNights = this.toggleNights.bind(this); 
        this.toggleIsraeliAirlines = this.toggleIsraeliAirlines.bind(this);
        this.toggleDestinationSelection = this.toggleDestinationSelection.bind(this); 
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.handleCloseCalendar = this.handleCloseCalendar.bind(this); 
        this.handleNext = this.handleNext.bind(this); 
        
        this.handleToggleFilters  = this.handleToggleFilters.bind(this);

        this.ModifyAdults = this.ModifyAdults.bind(this);
        this.ModifyTeens = this.ModifyTeens.bind(this);
        this.ModifyChildren = this.ModifyChildren.bind(this);
        this.ModifyInfants = this.ModifyInfants.bind(this);
        this.ResetDates = this.ResetDates.bind(this);
        
    }


    handleSubmit = (e, source=null) => {
        if (source=='desktop') {
            window.dataLayer = window.dataLayer || [];
            const event_name = 'SearchButton';
                console.log('GTM event: ' + event_name)
            window.dataLayer.push({
                event: event_name,
                'pageUrl': window.location,
                'pageTitle' : window.title,
                'platform': 'desktop'
            });
        }

        this.setState((prevState) => (
            {
                filters: {
                    ...prevState.filters,  
                    'window': {
                        ...prevState.filters.window,
                        'visible': false ,
                        'step': '0'
                    }
                }            
            }
        ), () => {
                //console.log('Filters requested:')
                //console.log(this.state.filters)
                this.props.sendDataToCaller(this.state.filters, true)
            }
        )
            
    }
    toggleCalendar = (event) => {
        event.stopPropagation();

        this.ResetDates()
        
        console.log('toggle picker')
        this.setState(prevState => (
            {
                ...prevState, 
                calendar:!prevState?.calendar,
                
            }
        )
        )
    }

    /*handleClick = (event, autoSubmit) => {
        console.log(autoSubmit)
        if (event) event.stopPropagation()
        //Event("FILTERS", "Search button clicked", window.location.pathname);
        

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'SearchButton',
            'pageUrl': window.location,
            'pageTitle' : window.title
        });

    
        //console.log(this.state);
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                window: {
                    //...prevState.filters.window,
                    visible: false,
                    //popup: (autoSubmit===true?false:this.state.filters.window?.popup)
                }
            }
        }),
        ()=>{
            if (autoSubmit)
                this.props.sendDataToCaller(this.state.filters, true)
        });

    }*/


    /**
     * Update state based on origin field change
     */
     handleSelectOrigin = event => {
        //Event("FILTERS", "Search button clicked", window.location.pathname);

        const newState = this.state;

        event.target.value=='TLV'?
            newState.filters.origin.iata.TLV = event.target.checked :
            newState.filters.origin.iata.ETM = event.target.checked ;
        
        if (!newState.filters.origin.iata.TLV && !newState.filters.origin.iata.ETM)
            newState.filters.origin.iata.TLV = true;
        
        this.setState(newState);

        // overwrite old state variable
        if (newState.filters.origin.iata.TLV && newState.filters.origin.iata.ETM)
            newState.filters.origin.from_airport = 'all'
        else {
            if (newState.filters.origin.iata.TLV)
                newState.filters.origin.from_airport = 'TLV'
            else
                if (newState.filters.origin.iata.ETM)
                    newState.filters.origin.from_airport = 'ETM'
                else
                    newState.filters.origin.from_airport = 'all'
        }
        
        this.setState(newState);

    }

    /** 
     * Update destination state based on selection
     */
   handleSelectDestination = (event, cityId, cityName) => {
        if (event)
            event.stopPropagation()

            
        //window.location = '/' + cityName + '?destination=' + cityId
        const newState = this.state;
        
        newState.filters.destination = cityId;
        newState.filters.destination_name = cityName; 
        newState.filters.window.visible = true;
        newState.filters.window.active_tab = 'search';

        this.setState(newState);

   }

    /**
     * Update filters state based on checkbox state (STARS / NIGHTS)
     */
   handleChangeCheckbox = (event) => {
        const newState = this.state;
        const value = event.target.value*1;

        // select target
        let max,min;
        
        if (event.target.name==='stars') {
            max = this.state.filters.max_stars;
            min = this.state.filters.min_stars;
        }
        if (event.target.name==='nights') {
            max = this.state.filters.max_nights;
            min = this.state.filters.min_nights;
        }

        if (!min || !max)
            return;

        // IF checked
        if (event.target.checked) {
            if (value >= max)
                max = value
            else {
                // if new value smaller than min => this is the new min
                if (value<=min)
                    min = value;
                else
                    return
            } 
        } else {
            // If UnCHECKED
            if (value == max) {
                if (value>min) {
                    max=value-1;
                } else {
                    return
                }
            } else 
                if (value==min) {
                    if (value<max) {
                        min=value+1;
                    } else {
                        return
                    }
                } else {
                    return
                }
        }
            
        // save state
        if (event.target.name==='stars') {
            newState.filters.max_stars = max;
            newState.filters.min_stars = min;
        }
        else {
            newState.filters.max_nights = max;
            newState.filters.min_nights = min;
        }
        
        this.setState(newState);
        //this.setState(newState,
        //   this.props.sendData(this.state.filters, event.target));
   };
    

   handleSelectMonth = (event) => {
        if (event)
            event.stopPropagation()

        const newState = this.state;

        // select target
        let list = this.state.filters.months;
        //console.log(event.target.checked)
        // update array
        if (event.target.checked)
            list = [...list, event.target.value]
        else
            list.splice(list.indexOf(event.target.value), 1);

        //console.log(list)
        
        newState.filters.months = list;
        
        //this.setState(newState);

   };

    // Handle sort button
   toggleDates = () => {
        this.ResetMonths()

        this.setState((prevState) => ({
            ...prevState,
            calendar: true,
            filters: {
                ...prevState.filters,
                when: 'dates'
            }
        })
        //,
        //this.props.sendDataToCaller(this.state.filters, true)
    );
   }

    /*
    getHolidayIcon = (uid) => {
        switch(uid) {
            //case 'rosh-hashana':    return (<RoshHashanaIcon />);
            case 'rosh-hashana':    return `<img src='${require('../../images/icons/rosh-hashana.svg')}' />`;
            case 'yom-kipur':       return (<YomKipurIcon />);
            case 'sukkot':          return (<SukkotIcon />);
            case 'christmas':       return (<ChristmasIcon />);
            case 'christmas-sales': return (<ChristmasSalesIcon />);
            case 'hanukkah':        return (<HanukkaIcon />);
            case 'purim':           return (<PurimIcon />);
            case 'passover':        return (<PassoverIcon />);
            case 'independence-day':return (<IndependenseDayIcon />);
            case 'sabbose':         return (<SabboseIcon />);
            case 'summer-vacation': return (<SummerVacationIcon />);
      

        }
    }
    */

   toggleHoliday = (value, caption, start_date, end_date) => {

        let holidays = [];

        if (this.state.filters?.holidays && this.state.filters?.holidays.length > 0) 
            holidays = this.state.filters.holidays;

        /*if (holidays.find(holiday=>holiday===value)) {
            // remove holiday
            holidays = holidays.filter(item=>item!==value)
        } else {
            // add holiday
            holidays.push(value)
        }*/
        holidays = [];
        holidays.push(value);
        
        let when = 'all';
        let when_caption = 'בכל התאריכים';

        if (holidays.length > 0) {
            when = 'holidays';
            when_caption = caption //'בחגים הנבחרים';
        }

        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    when: when,
                    when_caption: when_caption,
                    holidays: holidays,
                    holidays_caption: caption,
                    from_date: start_date.split('T')[0],
                    to_date: end_date.split('T')[0],
                    dates_type:'range',
                    from: null,
                    to: null,
                    months: [],
                    dow: [],
                    nights: [],
                }
            })
            //,
            //this.props.sendDataToCaller(this.state.filters, true)
        );
        //console.log(this.state.filters)
   }

   toggleDOW = (value) => {
        
        console.log(`toggle dow ${value}`)

       // If all selected reset array (meaning all nights are acceptable)
       if (value==='all' || value=='') {
            this.ResetDow()
            return;
        }
       
        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    dow: value.split(','),
                    from_date: '',
                    to_date: '',
                }
            })
        );
   }

    
   ResetStars = () => {
        //clear all months selections
        //newState.filters.months.splice(0, newState.filters.months.length);

        console.log('reset stars')
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                stars: []
            }
        }));
   }

   
   ResetMeals = () => {
        //clear all  selections

        console.log('reset meals')
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                meals: []
            }
        }));
   }

   ResetDow = () => {

        console.log('reset dow')
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                dow: [],
                from_date: '',
                to_date: '',
            }
        }));
   }


   ResetNights = () => {

        console.log('reset nights')
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                nights: []
            }
        }));
   }

   
    ResetMonths = () => {
        //clear all months selections
        //newState.filters.months.splice(0, newState.filters.months.length);

        console.log('reset months')

        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                months: [],
                dow: [],
                holidays: [],
                dates_type: '',
                
            }
        })
      );

    }

    // Refresh=true will refresh the caller component (i.e. the parent component) and the results displayed (otherwise it will only reset the filters)
    ResetDates = (refresh=false) => {
        //clear all months selections
        //newState.filters.months.splice(0, newState.filters.months.length);

        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                from_date: '',
                to_date: '',
                from: null,
                to: null,
                when: 'all',
                when_caption: 'בתאריכים הכי זולים',
                dates_type: '',
                holidays: []
            }
        })
        ,()=>{
            if (refresh)
                this.props.sendDataToCaller(this.state.filters, true)
        });

    }

    resetDestinations = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                destinations: []
            }
        })
      );

    }

    // Handle month button clicks
    toggleMonth = (value) => {

        // dont do anything is empty month box was clicked
        if (!value) return;
        
        console.log(`toggleMonth ${value}`)
        this.ResetDates()

        // check if value already in the list and if it is remove it, otherwise add it.
        let months = [];
        let when = 'all';

        if (this.state.filters?.months && this.state.filters?.months.length > 0) 
            months = this.state.filters.months;
        
        
        // Check if 'all' button was clicked
        if (value==='all') {
            if (months.length > 0) {
                this.ResetMonths()
                months = [];
            }

            this.setState((prevState) => ({
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        when: value,
                        when_caption: 'בתאריכים הכי זולים',
                        months: months,
                        dow: [],
                        nights: [],
                        holidays: []
                    }
                })
            );            
        } else {

            if (months.find(month=>month===value)) {
                // remove month
                months = months.filter(month=>month!==value)
            } else {
                // add month
                months.push(value)
            }

            if (months.length > 0)
                when = 'months'

            this.setState((prevState) => ({
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        when: when,
                        when_caption: 'בחודשים הנבחרים',
                        months: months,
                        dow: [],
                        nights: [],
                        holidays: []
                    }
                })
            );
        }


    }

    // Handle meal code filter clicks
    toggleMealCode = (value) => {

        // If all selected reset array (meaning all stars are acceptable)
        if (value==='all') {
            this.ResetMeals();
            return;
        }

        let meals = this.state.filters.meals;
        // check if value already in the list and if it is remove it, otherwise add it.
        if (meals.find(meal=>meal===value)) {
            // remove star
            meals = meals.filter(meal=>meal!==value)
        } else {
            // add star
            meals.push(value)
        }

        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    meals: meals
                }
            })
        );
    }

    toggleWaterpark = () => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                highlights: {
                    //...prevState.filters.highlights,
                    waterpark: !prevState.filters.highlights.waterpark*1
                }
            }
        })
    );
    }

    toggleLuxury = () => {
        this.ResetStars();
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                highlights: {
                    //...prevState.filters.highlights,
                    luxury: !prevState.filters.highlights.luxury*1
                }
            }
        })
    );
    }

    toggleCasino = () => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                highlights: {
                    //...prevState.filters.highlights,
                    casino: !prevState.filters.highlights.casino*1
                }
            }
        })
    );
    }

    toggleNearChabad = () => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                highlights: {
                    //...prevState.filters.highlights,
                    near_chabad: !prevState.filters.highlights.near_chabad*1
                }
            }
        })
    );
    }

    toggleSpa = () => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                highlights: {
                    //...prevState.filters.highlights,
                    spa: !prevState.filters.highlights.spa*1
                }
            }
        })
    );
    }

    toggleResetSpecialHotel = () => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                highlights: {}   
            }
        }))
    }

    toggleChildren = (value) => {
        this.setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                children: value
            }
        })
    );
    }
    
    // Handle stars filter clicks
    toggleStars = (value) => {

        // If all selected reset array (meaning all stars are acceptable)
        if (value==='all') {
            this.ResetStars()
            return;
        }

        let stars = this.state.filters.stars;
        // check if value already in the list and if it is remove it, otherwise add it.
        if (stars.find(star=>star===value)) {
            // remove star
            stars = stars.filter(star=>star!==value)
        } else {
            // add star
            stars.push(value)
        }

        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    stars: stars
                }
            })
        );
    }

    // Handle nights filter clicks
    toggleNights = (value) => {

        // If all selected reset array (meaning all nights are acceptable)
        if (value==='all') {
            this.ResetNights()
            return;
        }

        let nights = this.state.filters.nights;
        // check if value already in the list and if it is remove it, otherwise add it.
        if (nights.find(night=>night===value)) {
            // remove star
            nights = nights.filter(night=>night!==value)
        } else {
            // add star
            nights.push(value)
        }

        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    nights: nights
                }
            })
        );
    }

    toggleIsraeliAirlines = (value) => {

        // If all selected reset array (meaning all nights are acceptable)
        let airlines = [];
        
        if (value != 'all')
            airlines = 'LY,IZ,6H';

        this.setState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    airlines: airlines
                }
            })
        );
    }

    toggleDestinationSelection = (event, value) => {
        
        //console.log('toggleDestinationSelection')
        //console.log(value)
        //console.log(this.state.filters.destinations)
        // Fixes issue that causes the function to be executed twice if the checkbox's label is being clicked
        if (event.target.checked==undefined)
            return;

        event.stopPropagation();
        
        if (value=='all') {
            this.setState((prevState)=>(
                {
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        destinations: []
                    }
                }),function(){
                    //console.log(this.state.filters.destinations)
                })                    
        
            return;
        }

        // create (non referencial) array clone
        let destinationsArray = this.state.filters?.destinations; 

        if (destinationsArray.includes(value)) {

            // if value already exists remove it from array
            destinationsArray = destinationsArray.filter(item => item !== value);

            this.setState((prevState)=>(
            {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    destinations: destinationsArray
                }
            }),function(){
                //console.log(this.state.filters.destinations)
            })                    
        } else {
            // Add new destination
            console.log(`Add ${value}`)
            destinationsArray.push(value)

            // add it to array
            this.setState((prevState)=>(
            {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    destinations: destinationsArray
                }
            }), function(){
                //console.log(this.state.filters.destinations)
            })
        }
    }

    handleCalendarChange = (dates, datesType) => {
        
        const from = (dates[0])
        const to = (dates[1])

        if (!from && !to) return

        const sqlFromDate = !dates[0]?null:(from.getFullYear()) + '-' + (from.getMonth()*1+1) + '-' + from.getDate();
        const sqlToDate = !dates[1]?null:(to.getFullYear()) + '-' + (to.getMonth()*1+1) + '-' + to.getDate();

        this.setState(prevState=>({
            ...prevState,
            filters: {
                ...prevState.filters,
                from_date: sqlFromDate,
                from: from,
                to_date: sqlToDate,//toDate?toDate.getFullYear() + "-" + ((toDate.getMonth()*1)+1) + '-' + toDate.getDate():null,
                to: to,
                when_caption:  !to?
                    from.getDate() + "/" + (from.getMonth()*1+1) + '/' + (from.getFullYear()+'').replace('20',''):
                    from.getDate() + "/" + (from.getMonth()*1+1) + '/' + (from.getFullYear()+'').replace('20','') + " עד " + (to.getDate() + "/" + (to.getMonth()*1+1)) + '/' + ((to.getFullYear()+'').replace('20',''))
                ,
                dates_type: datesType,
                when: datesType,
                months: [],
                holidays: [],
                months: [], // reset months,
                dow: [], // reset dow selection
                nights: [], // reset nights,
            },
            calendar: !to // show date picker until target date selected

        }))
    }

    
  handleCalendarChange = (dates, datesType) => {
    
    console.log(`Refreshing calendar ${datesType} ${dates}`)
    const from = (dates[0])
    const to = (dates[1])

    if (!from && !to) return

    const sqlFromDate = !dates[0]?null:(from.getFullYear()) + '-' + (from.getMonth()*1+1) + '-' + from.getDate();
    const sqlToDate = !dates[1]?null:(to.getFullYear()) + '-' + (to.getMonth()*1+1) + '-' + to.getDate();

    this.setState(prevState=>({
        ...prevState,
        filters: {
            ...prevState.filters,
            from_date: sqlFromDate,
            from: from,
            to_date: sqlToDate,//toDate?toDate.getFullYear() + "-" + ((toDate.getMonth()*1)+1) + '-' + toDate.getDate():null,
            to: to,
            when_caption:  !to?
                from.getDate() + "/" + (from.getMonth()*1+1) + '/' + (from.getFullYear()+'').replace('20',''):
                from.getDate() + "/" + (from.getMonth()*1+1) + '/' + (from.getFullYear()+'').replace('20','') + " עד " + (to.getDate() + "/" + (to.getMonth()*1+1)) + '/' + ((to.getFullYear()+'').replace('20',''))
            ,
            dates_type: datesType,
            when: datesType,
            months: [],
            holidays: [],
            months: [], // reset months,
            dow: [], // reset dow selection
            nights: [], // reset nights,
        },
        calendar: !to // show date picker until target date selected

    }))
  }

  toggleDestinations = (event) => {
    console.log('toggle destinations')

    // Load destinations only once (we can't relay of the this.props.destination to be empty because it's filled by default by the getPackages function but not always will all destinations!)
    if ( (!this.props?.destinations || this.props?.destinations?.length<=1)) 
        this.props.fetchDestinations();
    
    this.setState(prevState => (
        {
            ...prevState, 
            filters: {
                ...prevState.filters,
                showDestinationsWindow:!prevState?.showDestinationsWindow,
            }
        }
    ))
}


  handleCloseCalendar = (params) => {
    this.setState(prevState => (
        {
            ...prevState, 
            calendar:false,
            
        }
    ))
}


  handleToggleFilters = (event, step='0', next_step_close=false) => {
      
      this.setState((prevState) => (
          {
              ...prevState,
              filters: {
                  ...prevState.filters,  
                  'window': {
                      ...prevState.filters.windows,
                      'visible': true,
                      'active_tab': 'search',
                      'step': step+'',
                      'next_step_close': next_step_close
                  }
              }            
          }
      ));

      if (step=='0' && next_step_close==false ) {
          window.dataLayer = window.dataLayer || [];
          const event_name = 'toggleSearch';
          console.log('GTM event: ' + event_name)
          window.dataLayer.push({
              event: event_name,
              'pageUrl': window.location,
              'pageTitle' : window.title,
              platform: 'mobile'
          });
      }
      
  }


  

  handleNext = (e) => {
      const step = this.props?.filters?.window?.step;
      const next_step_close = this.props?.filters?.window?.next_step_close;

      console.log(`handleNext() -> currentstep: ${step} close? ${next_step_close}`)

      // Reset filters that cannot be modified in the search window (unless we're only changing the pax - which can also be loaded form the filters)
      /*if (step != 0 )
      // OBSOLETE - SINEC WE HAVE ADDED THEM TO THE SEARCH WINDOW AS WELL
        this.toggleResetSpecialHotel(e);*/

      if ((step=='3' || step=='4') && !next_step_close) {
          
          this.handleSubmit(e)
      }
      else {
          if (next_step_close) {
            this.props.closeSearchWindow();
            this.handleSubmit()
          } else {
            if (step=='4') {
                this.props.gotoStep(e,'2')
            } else {
                //if ((this.state?.filters?.dates_type==='exact'))
                //    this.props.gotoStep(e,'3')
                //else
                    this.props.gotoStep(e, step*1+1)
            }
          }
      }
  };

  handleDestinationsChange = (selectedDestinations) => {
    console.log('handleDestinationsChange')
    console.log(selectedDestinations)
    
    this.setState(prevState => (
        {
            ...prevState, 
            
            filters: {
                ...prevState.filters,
                showDestinationsWindow: false,
                destinations: selectedDestinations
            }
        }
    ))
  }

  ModifyAdults = (change) => {
    const index =0;
    console.log('Adults: ' + change + ' (' + index + ')')
    //console.log(this.state)
    const roomsCopy = [...this.state?.filters.rooms] // Create a shadow copy of the array
    console.log(roomsCopy)

    // Addition
    if (change>0) {
      
      if (roomsCopy[index].adults.length>=this.max_adults_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן להוסיף יותר מ-${this.max_adults_per_room} מבוגרים `
        })
        return;
      }

      if (roomsCopy[index].adults.length+roomsCopy[index].teens.length+roomsCopy[index].children.length>=this.max_guests_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן להוסיף יותר מ-${this.max_guests_per_room} אורחים `
        })
        return;
      }

      if (this.CountGuests(roomsCopy) >= this.max_guests_per_booking ) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לבחור יותר מ-${this.max_guests_per_booking} נוסעים בחיפוש`
        })
        return;
      }

      // add guests
      roomsCopy[index].adults.push(30); //+=change; // remove 1 element starting from the index position
    }
    
    // Substraction
    if (change<0) {
      if(roomsCopy[index].adults.length>1) {
        roomsCopy[index].adults.splice(0,1) // +=change; // remove 1 element starting from the index position
      }
    }  

    // Update state with new pax
    this.setState((prevState) => (
        {
            ...prevState,
            filters: {
                ...prevState.filters,
                adults: roomsCopy[0].adults.length,
                teens: roomsCopy[0].teens.length,
                children: roomsCopy[0].children.length,
                infants: roomsCopy[0].infants.length,
                rooms: roomsCopy
            },
            err: ""
        }));


}

ModifyTeens = (change) => {
    const index =0;
    console.log('teens: ' + change + ' (' + index + ')')
    const roomsCopy = [...this.state.filters.rooms] // Create a shadow copy of the array

    // Addition
    if (change>0) {
     
      if (roomsCopy[index].adults.length+roomsCopy[index].teens.length+roomsCopy[index].children.length>=this.max_guests_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לחפש דילים ליותר מ-${this.max_guests_per_room} אורחים `
        })
        return;
      }

      if (this.CountGuests(roomsCopy) >= this.max_guests_per_booking ) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לחפש דילים ליותר מ-${this.max_guests_per_booking} נוסעים `
        })
        return;
      }

      // add guests
      roomsCopy[index].teens.push(16); //+=change; // remove 1 element starting from the index position
    }
    
    // Substraction
    if (change<0) {
      if(roomsCopy[index].teens.length>0) {
        roomsCopy[index].teens.splice(0,1) // +=change; // remove 1 element starting from the index position
      }
    }  

    console.log(roomsCopy)

   // Update state with new pax
   this.setState((prevState) => (
    {
        ...prevState,
        filters: {
            ...prevState.filters,
            adults: roomsCopy[0].adults.length,
            teens: roomsCopy[0].teens.length,
            children: roomsCopy[0].children.length,
            infants: roomsCopy[0].infants.length,
            rooms: roomsCopy
        },
        err: ""
    }),()=>{
        console.log(`Filters state changed:  `)
        console.log(this.state.filters)
    });
  }

ModifyChildren = (change) => {
    const index =0;
    console.log('Children: ' + change + ' (' + index + ')')
    const roomsCopy = [...this.state.filters.rooms] // Create a shadow copy of the array
    let warning = '';
    // Addition
    if (change > 0) {

      if (this.CountGuests(roomsCopy) >= this.max_guests_per_booking ) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לחפש דילים ליותר מ-${this.max_guests_per_booking} נוסעים `
        })

        return;
      }
      
      if (roomsCopy[index].children.length + roomsCopy[index].infants.length>=this.max_children_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לבחור יותר מ-${this.max_children_per_room} תינוקות/ילדים `
        })
        return;
      }

      if (roomsCopy[index].adults.length+roomsCopy[index].teens.length+roomsCopy[index].children.length+roomsCopy[index].infants.length>=this.max_guests_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לבחור יותר מ-${this.max_guests_per_room} אורחים `
        })
        return;
      }

      if (roomsCopy[index].children.length>=2) {
        warning = `מלונות רבים מגבילים את מספר הילדים בחדר ל-2 בלבד. על כן, במידה ולא תתקבל תוצאה, מומלץ לפצל את האורחים בין מספר חדרים`;
      }

      // Add child
      roomsCopy[index].children.push(5) // +=change; // remove 1 element starting from the index position
    }

    // Substraction
    if (change<0 && roomsCopy[index].children.length>0) {
      //console.log('substraction')
      roomsCopy[index].children.splice((roomsCopy[index].children.length-1),1); //+=change; // remove 1 element starting from the index position
    }
    
    // Update state with new pax
    this.setState((prevState) => (
        {
            ...prevState,
            filters: {
                ...prevState.filters,
                adults: roomsCopy[0].adults.length,
                teens: roomsCopy[0].teens.length,
                children: roomsCopy[0].children.length,
                infants: roomsCopy[0].infants.length,
                rooms: roomsCopy
            },
            err: ""
        }));


}

ModifyInfants = (change) => {
    const index =0;
    console.log('infants: ' + change + ' (' + index + ')')
    const roomsCopy = [...this.state.filters.rooms] // Create a shadow copy of the array
    let warning = '';
    // Addition
    if (change > 0) {

      if (this.CountGuests(roomsCopy) >= this.max_guests_per_booking ) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לבחור יותר מ-${this.max_guests_per_booking} נוסעים `
        })

        return;
      }
      
      if (roomsCopy[index].children.length+roomsCopy[index].infants.length>=this.max_children_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לבחור יותר מ-${this.max_children_per_room} תינוקות/ילדים `
        })
        return;
      }

      if (roomsCopy[index].adults.length+roomsCopy[index].teens.length+roomsCopy[index].children.length+roomsCopy[index].infants.length>=this.max_guests_per_room) {
        this.setState({ 
          ...this.state, 
          err: `לא ניתן לבחור יותר מ-${this.max_guests_per_room} נוסעים `
        })
        return;
      }

      if (roomsCopy[index].children.length+roomsCopy[index].infants.length>=2) {
        warning = `מלונות רבים מגבילים את מספר הילדים בחדר ל-2 בלבד. על כן, במידה ולא תתקבל תוצאה, מומלץ לפצל את האורחים בין מספר חדרים`;
      }

      // Add an infant
      roomsCopy[index].infants.push(1) // +=change; // remove 1 element starting from the index position
    }

    // Substraction
    if (change<0 && roomsCopy[index].infants.length>0) {
      //console.log('substraction')
      roomsCopy[index].infants.splice((roomsCopy[index].infants.length-1),1); //+=change; // remove 1 element starting from the index position
    }
    
    // Update state with new pax
    this.setState((prevState) => (
        {
            ...prevState,
            filters: {
                ...prevState.filters,
                adults: roomsCopy[0].adults.length,
                teens: roomsCopy[0].teens.length,
                children: roomsCopy[0].children.length,
                infants: roomsCopy[0].infants.length,
                rooms: roomsCopy
            },
            err: ""
        }));


}

CountGuests = (rooms) => {
    return rooms.reduce((partialSum, room) => partialSum + room.adults.length + room.teens.length +  room.children.length + room.infants.length, 0);
}
    handleCloseDestinationsWindow = () => {
        console.log('handleCloseDestinationsWindow')

        this.setState(prevState => (
            {
                ...prevState, 
                
                filters: {
                    ...prevState.filters,
                    showDestinationsWindow: false,
                }
            }
        ))/*,()=>{
            this.props.sendDataToCaller(this.state.filters, false)
        })*/
    }

    render() {
        return (
            <>
                <GlobalFilters 
                    handleCloseDestinationsWindow={this.handleCloseDestinationsWindow}
                    handleDestinationsChange={this.handleDestinationsChange}
                    handleCalendarChange={this.handleCalendarChange}
                    filters={this.state.filters}
                    callFetchDestinations={this.props.fetchDestinations}
                    destinations={this.props?.destinations}
                    ResetDates={this.ResetDates}
                    resetDestinations={this.resetDestinations}
                    toggleDestinations={this.toggleDestinations}
                    showSearchWindow={this.props.showSearchWindow}
                    destination_name={this.state.filters?.destination?.city_name}
                    toggleMonth={this.toggleMonth}
                    toggleHoliday={this.toggleHoliday}
                    toggleCalendar={this.toggleCalendar}
                    toggleNights={this.toggleNights}
                    toggleDOW={this.toggleDOW}
                    toggleMealCode={this.toggleMealCode}
                    toggleWaterpark={this.toggleWaterpark}
                    toggleCasino={this.toggleCasino}
                    toggleLuxury={this.toggleLuxury}
                    toggleNearChabad={this.toggleNearChabad}
                    toggleSpa={this.toggleSpa}
                    toggleResetSpecialHotel={this.toggleResetSpecialHotel}
                    toggleStars={this.toggleStars}
                    toggleChildren={this.toggleChildren}
                    toggleIsraeliAirlines={this.toggleIsraeliAirlines}
                    toggleDestinationSelection={this.toggleDestinationSelection}
                    gotoStep={this.props.gotoStep}
                    handleClose={this.props.closeSearchWindow}
                    handleCloseCalendar={this.handleCloseCalendar}
                    handleNext={this.handleNext}
                    handleToggleFilters={this.handleToggleFilters}
                    calendar={this.state.calendar}
                    month_name={this.month_name}
                    month={this.month}
                    year={this.year} 
                    ModifyAdults={this.ModifyAdults}
                    ModifyTeens={this.ModifyTeens}
                    ModifyChildren={this.ModifyChildren}
                    ModifyInfants={this.ModifyInfants}    
                    handleSubmit={this.handleSubmit}
                ></GlobalFilters>

                <Search 
                    style={{display:'none'}}
                    handleCalendarChange={this.handleCalendarChange}
                    filters={this.state?.filters}
                    type="deals"
                    step={this.props.filters.window.step}
                    location={this.props.location}
                    is_open={this.props.filters?.window?.visible}
                    open={this.props.filters?.window?.visible?this.props.filters?.window?.visible:false}
                    toggleMonth={this.toggleMonth}
                    toggleHoliday={this.toggleHoliday}
                    toggleCalendar={this.toggleCalendar}
                    toggleNights={this.toggleNights}
                    toggleDOW={this.toggleDOW}
                    toggleMealCode={this.toggleMealCode}
                    toggleWaterpark={this.toggleWaterpark}
                    toggleCasino={this.toggleCasino}
                    toggleLuxury={this.toggleLuxury}
                    toggleNearChabad={this.toggleNearChabad}
                    toggleSpa={this.toggleSpa}
                    toggleResetSpecialHotel={this.toggleResetSpecialHotel}
                    toggleStars={this.toggleStars}
                    toggleChildren={this.toggleChildren}
                    toggleDestinationSelection={this.toggleDestinationSelection}
                    resetDestinations={this.resetDestinations}
                    toggleDestinations={this.toggleDestinations}
                    gotoStep={this.props.gotoStep}
                    handleClose={this.props.closeSearchWindow}
                    handleCloseCalendar={this.handleCloseCalendar}
                    handleNext={this.handleNext}
                    handleToggleFilters={this.handleToggleFilters}
                    calendar={this.state.calendar}
                    ModifyAdults={this.ModifyAdults}
                    ModifyTeens={this.ModifyTeens}
                    ModifyChildren={this.ModifyChildren}
                    ModifyInfants={this.ModifyInfants}
                    handleSubmit={this.handleSubmit}
                />

                <Grid>
                    <Snackbar
                        tabIndex={0}
                        open={this.state?.err && this.state?.err!==''}
                        style={{textAlign:'right', height:'40%'}}
                        autoHideDuration={2000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        key={'middlecenter'}
                        onClose={()=>{
                            this.setState({ 
                                ...this.state, 
                                err: ''
                                }
                            )}}
                        >
                            <Alert 
                                tabIndex={0}
                                elevation={6} 
                                variant="filled" 
                                severity="error"
                                /*action={
                                    <Button color="inherit" size="small" onClick={()=>{
                                    setState(
                                        { 
                                        ...state, 
                                        err: ''
                                        }
                                    )}}>
                                    x
                                    </Button>
                                }*/
                                onClose={()=>{
                                    this.setState({ 
                                        ...this.state, 
                                        err: ''
                                    }
                                    )}}>
                            <AlertTitle style={{fontWeight:600}}>שגיאה</AlertTitle>
                            {this.state.err}</Alert>
                    </Snackbar>

                    <Snackbar
                        tabIndex={0}
                        open={this.state?.warn && this.state?.warn!==''}
                        autoHideDuration={2000}
                        style={{ textAlign:'right', height:'40%' }}
                        anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                        }}
                        key={'topcenter'}
                        onClose={()=>{
                        this.setState({ 
                            ...this.state, 
                            warn: ''
                            }
                        )}}>
                        <Alert 
                        tabIndex={0}
                        elevation={6} 
                        variant="filled" 
                        severity="warning"
                        onClose={()=>{
                            this.setState({ 
                                ...this.state, 
                                warn: ''
                            }
                            )}}>
                            <AlertTitle style={{fontWeight:600}}>שימו לב</AlertTitle>
                            {this.state?.warn}</Alert>
                    </Snackbar>
                </Grid>
            </>
        )
    }

} export default FiltersManager
