import React from "react";
import { useLocation } from 'react-router-dom';
import { Route, Switch, Router, Redirect} from "react-router-dom";
import { GlobalProvider } from "./contexts/GlobalState";
import { ToastProvider } from "react-toast-notifications";

//import { App, Deal, Flights, LastMinute } from "./components";
//import { Terms, Service, Contact } from "./static";
import App from "./App"
import Deal from "./components/Package/Package"
import Article from "./components/Article/Article"
//import Destination from "./components/Destination_old"
import Destination from "./components/Destination"
//import Destination2 from "./components/Destination"
import Reserve from "./components/Reservation/Form"
import Terms from "./components/Page/Terms"
import Privacy from "./components/Page/Privacy"
import Accessibility from "./components/Page/Accessibility"
//import Service from "./static/Service"
//import Contact from "./static/Contact"
//import NotFound from "./static/NotFound"
//import About from "./static/About"
//import Media from "./static/Media"
//import Faq from "./static/Faq"
import Promotion from "./components/Page/Promotion"
import Members from "./components/Page/Members"
import MemberTerms from "./components/Page/MemberTerms"
import MemberSignup from "./components/Page/MemberSignup";
import Newsletter from "./components/Page/Newsletter"
import {createBrowserHistory} from 'history'
//import Details from "./components/CreditCard/Details";
import Confirmation from "./components/PaymentConfirmation/Confirmation";
import Promotion199 from "./components/Homepage/Promotions/199"
import PromotionMax199 from "./components/Homepage/Promotions/Max199"
import PromotionMaxItaly from "./components/Homepage/Promotions/MaxItaly"

const history = createBrowserHistory()

// Switch between two destination page version based on the &v=2 parameter
/*const DestinationPages = (props) => {
  const { search } = useLocation();
  const Page = search.indexOf('v=2')>0?Destination2:Destination;
  return <Page {...props} />;
}*/

const Routes = () => {
    return (
      <Router history={history}>
      <GlobalProvider>
      <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="bottom-center"
        >
        <Switch>
          {/* Homepage */}
          <Route exact path="/" component={App} />          
          <Route path="/search" component={App} />
          <Route path="/best-deals" component={App} />
          <Route path="/last-minute" component={App} />
          <Route path="/one-week" component={App} />
          <Route path="/weekends" component={App} />
          <Route path="/waterparks" component={App} />
          <Route path="/luxury" component={App} />
          <Route path="/casino" component={App} />
          <Route path="/spa" component={App} />
          <Route path="/israeli" component={App} />
          <Route path="/near-chabad" component={App} />
          <Route path="/5-stars" component={App} />
          <Route path="/summer-deals" component={App} />
          <Route path="/holiday-season-deals" component={App} />
          <Route path="/xmas-deals" component={App} />
          <Route path="/passover" component={App} />
          <Route path="/passover-vacation-deals" component={App} />
          <Route path="/summer-family" component={App} />
          <Route path="/summer-vacation" component={App} />
          <Route path="/sukkot-vacation-deals" component={App} />          
          <Route path='/all-inclusive-deals' component={App} />
          <Route path='/early-birds' component={App} />


          {/*  Deals  */}
          <Route path="/deal/:deal_id/book/:quote_id" component={Reserve} />
          <Route path="/deal/:id" component={Deal} />
          <Route path="/article/:id" component={Article} />
          <Route path="/process_payment/:uid" component={Confirmation} />

          {/*  Static  */}
          <Route exact path="/terms.html" component={Terms} />
          <Route exact path="/privacy.html" component={Privacy} />
          <Route exact path="/accessibility.html" component={Accessibility} />
          {/*
          <Route exact path="/contact.html" component={Contact} />
          <Route exact path="/about.html" component={About} />
          <Route exact path="/media.html" component={Media} />
          <Route exact path="/faq.html" component={Faq} />
           */}
          <Route exact path="/members/" component={Members} />        
          <Route exact path="/members/terms.html" component={MemberTerms} />
          <Route exact path="/members/signup/" component={MemberSignup} />
          <Route exact path="/members/signin/" component={MemberSignup} />
          <Route exact path="/promotion/:id" component={Promotion} />
          <Route exact path="/join/imaleclub2" component={Newsletter} />

          {/* PROMOTIONS */}
          <Route path="/promotions/199" component={Promotion199} />
          <Route path="/promotions/max199" component={PromotionMax199} />
          <Route path="/promotions/maxitaly" component={PromotionMaxItaly} />

          {/*  Redirects  */}
          <Route exact path="/flights" component={App}><Redirect to="/" /></Route>
          <Route exact path="/flight/:id" component={App}><Redirect to="/" /></Route>
          <Route path="/vacations" component={App}><Redirect from="/vacations" to="/" /></Route>
          <Route path="/holidays" component={App}><Redirect from="/holidays" to="/" /></Route>
          <Route path="/families" component={App}><Redirect from="/families" to="/" /></Route>
          <Route path="/contact.html" component={App}><Redirect from="/contact.html" to="/article/contact" /></Route>
          <Route path="/media.html" component={App}><Redirect from="/media.html" to="/article/media" /></Route>
          <Route path="/about.html" component={App}><Redirect from="/about.html" to="/article/about" /></Route>
          <Route path="/faq.html" component={App}><Redirect from="/faq.html" to="/article/faq" /></Route>


          {/* Destination Pages */}
          <Route path="/:destination_name/:month" component={Destination} />
          <Route path="/:destination_name" component={Destination} />

          {/*<Route render={({ staticContext }) => {
            if (staticContext) {
              staticContext.status = 301;
            }
            return <Redirect from="/whatsapp/join2" to="https://chat.whatsapp.com/FZ6WeUWhzc93r88j4k4FH5" />
          }}/>*/}
          </Switch>
        </ToastProvider>
      </GlobalProvider>
      </Router>
    )
  }
  
  export default Routes